<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'

export interface Props {
  context: FormKitFrameworkContext
}

const { context } = defineProps<Props>()

const formatNumber = (value: string) => {
  if (!value) return ''
  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// Remove non-numeric characters and commas
const unformatNumber = (value: string) => {
  return value.replace(/\D/g, '').replace(/,/g, '')
}

const handleInput = (e: Event) => {
  const input = e.target as HTMLInputElement
  const value = input.value
  const unformattedValue = unformatNumber(value)
  input.value = formatNumber(unformattedValue)
  context.node.input(Number(unformattedValue))
}
</script>

<template>
  <div
    class="formkit-inner transition-ring mb-1 flex w-full items-center overflow-hidden rounded-lg ring-1 ring-black-10 duration-200 ease-in-out focus-within:ring-2 focus-within:ring-black-80 hover:ring-black-30 focus-within:hover:ring-black-80"
  >
    <input
      class="formkit-input w-full appearance-none bg-transparent bg-white p-[15px] text-base text-black-100 placeholder-black-60 focus:shadow-none focus:outline-none disabled:bg-black-5 disabled:text-black-30"
      type="text"
      :value="context.value ? formatNumber(context.value.toString()) : ''"
      @input="handleInput"
      @blur="context.handlers.blur"
    />
  </div>
</template> 